@import url('https://fonts.googleapis.com/css2?family=Freckle+Face&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Anton&family=Freckle+Face&family=Righteous&family=Pacifico&display=swap');



.dropping-text-container {
    position: relative;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    padding: 0 10px;
    /* Add padding to prevent overflow */
    box-sizing: border-box;
    /* Ensure padding is included in element width */
    gap: 10px;
    /* Add space between each text element */
}

/* Jiggle animation - repeats infinitely */
@keyframes jiggle {

    0%,
    100% {
        transform: translateY(0);
    }

    25% {
        transform: translateY(-5px);
    }

    50% {
        transform: translateY(5px);
    }

    75% {
        transform: translateT(-3px);
    }
}

@keyframes dropBounce {
    0% {
        transform: translateY(-100%);
        opacity: 0;
    }

    40% {
        transform: translateY(0);
        opacity: 1;
    }

    55% {
        transform: translateY(-30px);
    }

    70% {
        transform: translateY(0);
    }

    85% {
        transform: translateY(-15px);
    }

    100% {
        transform: translateY(0);
    }
}

.dropping-text {
    font-family: 'Freckle Face', cursive;
    text-shadow: 3px 3px 0 #000, -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
    animation: dropBounce 2s ease-in-out forwards;
    opacity: 100;
    text-align: center;
    /* Center align text */
    margin: 0;
    /* Remove default margin */
}

.dropping-text-1 {
    font-size: 5rem;
    /* Fixed font size for large screens */
    color: #ff6347;
    /* Tomato color */
}

.dropping-text-2 {
    font-size: 3rem;
    /* Fixed font size for large screens */
    animation-delay: 0.5s;
    color: #32cd32;
    /* LimeGreen color */
}

.dropping-text-3 {
    font-family: 'Arial', sans-serif;
    font-size: 3rem;
    /* Fixed font size for large screens */
    animation-delay: 1s;
    color: #1e90ff;
    /* DodgerBlue color */
}

.dropping-text-4 {
    font-family: 'Anton', sans-serif;
    font-size: 3rem;
    animation: jiggle 2s ease-in-out 2s infinite;
    /* Fixed font size for large screens */
    animation-delay: 1.5s;
    color: #ff6347;
    /* Orange color */
}

/* Small screen styles */
@media (max-width: 910px) {
    .dropping-text-1 {
        font-size: 3rem;
        /* Fixed font size for small screens */
    }

    .dropping-text-2 {
        font-size: 2rem;
        /* Fixed font size for small screens */
        animation-delay: 0.5s;
        color: #32cd32;
        /* LimeGreen color */
    }

    .dropping-text-3 {
        font-size: 2rem;
        /* Fixed font size for small screens */
        animation-delay: 1s;
        color: #1e90ff;
        /* DodgerBlue color */
    }

    .dropping-text-4 {
        font-size: 2rem;
        /* Fixed font size for small screens */
        animation-delay: 1.5s;
        color: #ff6347;
        /* Orange color */
    }
}