.instruction-text {
  font-family: 'Anton', sans-serif;
  /* Example font */
  font-size: 2rem;
  color: #ffffff;
  /* White color */
  margin-bottom: 20px;
  animation: flash 3s infinite;
  text-align: center;
  /* Center the text for better alignment */
}

.instruction-text .arrow {
  font-weight: bold;
  font-size: 40px;
}

/* Flash Animation */
@keyframes flash {

  0%,
  50%,
  100% {
    opacity: 1;
  }

  25%,
  75% {
    opacity: 0;
  }
}

/* Responsive Design */
@media (max-width: 900px) {
  .instruction-text {
    font-size: 1rem;
    /* Adjust the font size for smaller devices */
  }

  .instruction-text .arrow {
    font-size: 30px;
    /* Adjust arrow size for smaller screens */
  }
}