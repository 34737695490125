.quest-container {
  text-align: center;
  margin: auto;
  padding: 40px;
  width: 600px;
  height: 350px;
  border: 4px solid #0060d3;
  border-radius: 15px;
  background-color: #f5f5f5;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  position: relative;
  overflow: hidden;
  z-index: 5 !important;
}

.quest-container pre {
  background-color: #1e1e1e;
  color: #ffffff;
  padding: 20px;
  font-weight: bold;

  border-radius: 10px;
  width: auto;
  overflow-x: auto;
}

.quest-container button.quest-button {
  background-color: #0060d3;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;

  cursor: pointer;
}

.quest-container button.quest-button:hover {
  background-color: #0050b3;
}

.quest-container p.quest-success-message {
  color: #0060d3;
  margin-top: 20px;
}


.inline-code-container {
  display: flex;
  align-items: baseline;
  justify-content: flex-start;
  flex-wrap: nowrap;
  /* Prevent items from wrapping into next line */

}

.inline-code-input {
  flex-grow: 1;
  /* Allow the input to take up available horizontal space */
  border: none;
  outline: none;
  background: transparent;
  color: #ffdd00;
  /* make it stand out with a different color */
  font-family: 'Courier New', monospace;
  font-weight: bold;
  font-size: 1em;
  height: 50px;
  width: auto;
  min-width: 350px;
  margin: 0;
  padding: 0;
}